<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();

const pageData = [
	{
		id: "expirience",
		title: "player-first experience",
		titleGradient: "var(--ghaziabad)",
		text: "Here, fun never stops and there's a game for everyone. Enjoy classics and discover unique games, like Hold'n'Link and fishing adventures. Plus, with Daily Quests, you can win twice - in your game and by completing quests for extra prizes."
	},
	{
		id: "offers",
		title: "exclusive offers",
		titleGradient: "var(--goerlitz)",
		text: "Offers tailored just for you, filled with great value! Climb up in our VIP Club where more play means bigger rewards. Don't miss out on free daily prizes and exciting events happening all the time. FunzCity is your go-to place for endless fun and fantastic promos!"
	},
	{
		id: "free",
		title: "forever free",
		titleGradient: "var(--guliston)",
		text: "At FunzCity, the fun's on us – always free, always entertaining! No need to spend to play; just join in and enjoy endless gaming excitement. FunzCity is where you can play your way, for free, every day!"
	}
];
</script>

<template>
	<div class="benefits">
		<ASvg name="24/title-star" class="icon icon-star" />
		<ASvg name="24/title-star" class="icon icon-star-bottom" />
		<ASvg name="24/title-star-blur" class="icon icon-star-blur" />
		<div class="benefits-border" />
		<div class="benefits-title">
			<AText variant="tasikmalaya" :modifiers="['medium']" as="div" class="text-tlalnepantla">
				Why <b>FUNZCITY?</b>
			</AText>
		</div>
		<div class="benefits-content">
			<div v-for="(item, i) in pageData" :key="item.id" class="benefits__item" :class="item.id">
				<AText
					variant="taipei"
					as="div"
					:gradient="item.titleGradient"
					:modifiers="['bold', 'uppercase']"
					class="text-tlalnepantla benefits__item-title"
				>
					<span class="counter">{{ i + 1 }}</span>
					<span>{{ item.title }}</span>
				</AText>

				<AText variant="tempe" as="div" class="benefits__text">
					{{ item.text }}
				</AText>
			</div>
		</div>

		<div class="action">
			<AButton variant="primary" class="btn" size="xl" @click="open('LazyOModalSignup')">
				<AText variant="tanta" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ t("Create free account") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.benefits {
	position: relative;
	width: 1324px;
	height: 390px;
	max-width: calc(100% - 32px);
	margin: gutter(20) auto gutter(8);
	perspective: 1000px;

	@include media-breakpoint-down(lg) {
		max-width: 100%;
		margin: gutter(7) auto gutter(8);
		padding: gutter(4) 0;
		height: auto;
	}

	.icon-star,
	.icon-star-blur,
	.icon-star-bottom {
		position: absolute;

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.icon-star {
		font-size: 41px;
		right: -45px;
		top: -45px;

		@include media-breakpoint-down(xxl) {
			right: 0;
			top: -65px;
		}
	}
	.icon-star-blur {
		font-size: 82px;
		right: -15px;
		top: -110px;

		@include media-breakpoint-down(xxl) {
			right: 60px;
			top: -120px;
		}
	}

	.icon-star-bottom {
		font-size: 46px;
		left: -20px;
		bottom: -50px;
		filter: blur(3px);
	}

	&-border {
		position: absolute;
		width: calc(100% + 110px);
		height: 100%;
		background: var(--garoua);
		border-radius: 40px 30px 30px 40px;
		transform: rotateY(165deg) translateX(180px);

		@include media-breakpoint-down(xl) {
			width: 100%;
			transform: rotateY(165deg) translateX(8%);
		}

		@include media-breakpoint-down(lg) {
			transform: rotateY(165deg) translateX(6%);
		}

		@include media-breakpoint-down(md) {
			transform: rotateY(165deg) translateX(4%);
		}

		@include media-breakpoint-down(sm) {
			transform: none;
			background: transparent;
		}

		&::before {
			content: "";
			position: absolute;
			border-radius: 40px 30px 30px 40px;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			left: 2px;
			top: 2px;
			background: var(--carabanchel);

			@include media-breakpoint-down(sm) {
				background: url("~/assets/icons/border-mob.svg") center/contain no-repeat;
			}
		}
	}

	&-title {
		width: 387px;
		height: 150px;
		display: flex;
		justify-content: center;
		position: absolute;
		background-image: url("/nuxt-img/benefits/benefits-title.png");
		background-repeat: no-repeat;
		background-size: contain;
		left: calc(50% - 180px);
		top: -50px;
		padding-top: gutter(2);

		:deep(> div) {
			transform: rotate(-5deg);
		}

		@include media-breakpoint-down(lg) {
			padding-top: gutter(1.4);
			width: 240px;
			top: 20px;
			left: calc(50% - 120px);
		}
	}

	&__item {
		max-width: 296px;
		flex: 1;
		position: relative;

		@include media-breakpoint-down(xl) {
			max-width: 240px;
		}

		@include media-breakpoint-down(lg) {
			max-width: 296px;
		}

		&:nth-child(2) {
			&::before,
			&::after {
				content: "";
				position: absolute;
				top: calc(50% - 50px);
				width: 4px;
				height: 100px;
				border-radius: 20px;

				@include media-breakpoint-down(lg) {
					content: none;
				}
			}

			&::before {
				background: var(--guantanamo);
				left: -32px;
			}

			&::after {
				background: var(--garoua);
				right: -32px;
			}
		}

		&-title {
			max-width: 230px;
			display: flex;
			align-items: center;
			gap: gutter(2);

			.counter {
				font-size: 88px;
			}
		}
	}

	&-content {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: gutter(8);

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			gap: gutter(3);
			padding: gutter(9) 0 gutter(0.5);
		}
	}

	&__text {
		line-height: 1.4;
		margin-top: gutter(1);
	}

	.action {
		width: 100%;
		margin-top: gutter(-4);
		display: flex;
		justify-content: center;
		position: relative;
		z-index: 2;
		user-select: none;

		@include media-breakpoint-down(lg) {
			margin-top: 0;
			position: absolute;
			bottom: -50px;
		}
	}

	.btn {
		width: 280px;

		@include media-breakpoint-down(md) {
			width: 220px;
		}
	}
}
</style>
